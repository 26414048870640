import {Component} from '@angular/core';
import {IfCoreInputComponent} from "@if/angular-core-components";
import {FormsModule} from "@angular/forms";
import {NgClass} from "@angular/common";

@Component({
    selector: 'if-demo-demo-overwrite',
    imports: [
        FormsModule,
        NgClass
    ],
    templateUrl: './demo-overwrite.component.html',
    styles: ``
})
export class DemoOverwriteComponent extends IfCoreInputComponent {

}
